import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';
import { GraphQLParams, config } from '@tag/graphql';
import {
  PurchaseComment,
  PurchaseOrder,
  PurchaseOrderLine,
} from '@tag/graphql';

export interface Response {
  purchaseOrders: { items: PurchaseOrder[]; totalCount: number };
}

export interface ResponseLine {
  purchaseOrderLines: { items: PurchaseOrderLine[]; totalCount: number };
}

export interface ResponseComment {
  purchaseComments: { items: PurchaseComment[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrdersGQL extends Query<
  Response,
  GraphQLParams<'purchaseOrder'>
> {
  document = gql(config.purchaseOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderLinesGQL extends Query<
  ResponseLine,
  GraphQLParams<'purchaseOrderLine'>
> {
  document = gql(config.purchaseOrderLine.query);
}

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderCommentsGQL extends Query<
  ResponseComment,
  GraphQLParams<'purchaseComment'>
> {
  document = gql(config.purchaseComment.query);
}
