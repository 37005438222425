import { PurchaseOrderLine } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

/**
 * Get all Purchase Order Lines
 *
 * @param filter OData filter
 */
export class GetPurchaseOrderLines {
  static readonly type = '[POL] Get';

  constructor(public filter?: string) {}
}

/**
 * Add Purchase Order Line
 *
 * @param payload Purchase Order Line object
 */
export class AddPurchaseOrderLine {
  static readonly type = '[POL] Add';

  constructor(public payload: PurchaseOrderLine) {}
}

/**
 * Update purchase order, this method will dynamically update the cache purchase order list and selected purchase order.
 *
 * @param no purchase order Line no
 * @param line Purchase Order Line no
 * @param patch JSON patch object used to update the purchase order. ImPOLrt Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdatePurchaseOrderLine {
  static readonly type = '[POL] Update';

  constructor(
    public no: string,
    public line: number,
    public patch: Operation[],
    public selectedItem?: PurchaseOrderLine
  ) {}
}

/**
 * Delete purchase order, this method will dynamically update the cache purchase order list and set selected purchase order to null.
 *
 * @param no Purchase Order no
 * @param line Purchase Order Line no
 */
export class DeletePurchaseOrderLine {
  static readonly type = '[POL] Delete';

  constructor(public no: string, public line: number) {}
}

/**
 * Set selected purchase order Line
 *
 * @param payload New Purchase Order Line full object. Summary is not supPOLrted.
 */
export class SetSelectedPurchaseOrderLine {
  static readonly type = '[POL] Set';

  constructor(public payload: PurchaseOrderLine | null) {}
}

/**
 * Locally update purchase order lines when purchase order is created. Do not use directly.
 *
 * @param payload Work Order From Request Post Object
 * @param payload Work Order No
 * @deprecated
 */
export class UpdateLocalPurchaseOrderLinesFromDocumentCreation {
  static readonly type = '[POL] Update Local POLS';

  constructor(public pols: PurchaseOrderLine[]) {}
}
