import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { VendorsGQL } from '@shared/apollo/queries/vendor';
import { PurchaseOrdersGQL } from '@shared/apollo/queries/purchase-order';
import { PurchaseOrder, Vendor } from '@tag/graphql';
import { ApiService } from '@services/api.service';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderStoreService {
  constructor(
    private vendorsGQL: VendorsGQL,
    private poGQL: PurchaseOrdersGQL,
    private api: ApiService
  ) {}

  /**
   * Fetchs Purchase Order
   *
   * @param filter OData filter
   *
   * @returns Purchase Orders
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPurchaseOrders(filter?: string): Observable<PurchaseOrder[]> {
    return this.poGQL.watch({ filter }).valueChanges.pipe(
      map((result) => {
        return result.data.purchaseOrders.items;
      })
    );
  }

  /**
   * Fetchs Purchase Order
   *
   * @param no Document no
   *
   * @returns Purchase Orders
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPurchaseOrder(no: string): Observable<PurchaseOrder[]> {
    return this.poGQL.watch({ filter: `No eq '${no}'` }).valueChanges.pipe(
      map((result) => {
        return result.data.purchaseOrders.items;
      })
    );
  }

  /**
   * Deletes Purchase Order
   *
   * @param no
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   * @todo Add the new API call when ready
   */
  deletePurchaseOrder(no: string): Observable<any> {
    return of();
  }

  /**
   * Adds Purchase Order
   *
   * @param payload
   *
   * @returns Purchase Order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addPurchaseOrder(payload: any): Observable<any> {
    return this.api.post('/purchase-orders', payload);
  }

  /**
   * Updates Purchase Order
   *
   * @param payload
   *
   * @returns Purchase Order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updatePurchaseOrder(payload: any): Observable<any> {
    return this.api.post('/purchase-orders', payload);
  }

  /**
   * Send Purchase Order Approval Request
   *
   * @param no Purchase Order
   *
   * @returns Purchase Order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  approvePurchaseOrder(no: string): Observable<string> {
    return this.api.post(`/purchase-orders/${no}/request-approval`);
  }

  /**
   * Cancel Purchase Order Approval Request
   *
   * @param no Purchase Order
   *
   * @returns Purchase Order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  cancelPurchaseOrder(no: string): Observable<any> {
    return this.api.post(`/purchase-orders/${no}/cancel-approval`);
  }

  /**
   * Pick up Purchase Order
   *
   * @param no purchase Order no
   *
   * @returns Purchase Order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  pickUpPurchaseOrder(no: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  /**
   * Fetchs Vendors
   *
   *
   * @returns Vendors
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchVendors(filter?: string): Observable<Vendor[]> {
    return this.vendorsGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.vendors.items));
  }
}
