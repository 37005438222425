import { PurchaseOrder } from '@tag/graphql';
import { PurchaseOrderPost } from '@api/types';

/**
 * Get all Purchase Orders
 *
 * @param filter OData filter
 */
export class GetPurchaseOrders {
  static readonly type = '[PO] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get one Purchase Order
 *
 * @param filter OData filter
 */
export class GetPurchaseOrder {
  static readonly type = '[PO] Get One';

  constructor(public no: string) {}
}

/**
 * Add Purchase Order
 *
 * @param payload Purchase Order Post object
 */
export class AddPurchaseOrder {
  static readonly type = '[PO] Add';

  constructor(public payload: PurchaseOrderPost) {}
}

/**
 * Update purchase order, this method will dynamically update the cache purchase order list and selected purchase order.
 *
 * @param payload Purchase Order Post object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdatePurchaseOrder {
  static readonly type = '[PO] Update';

  constructor(
    public payload: PurchaseOrderPost,
    public selectedItem?: PurchaseOrder
  ) {}
}

/**
 * Send purchase order Approval Request, this method will dynamically update the cache purchase order list and selected purchase order.
 *
 * @param no Purchase Order
 */
export class ApprovePurchaseOrder {
  static readonly type = '[PO] Approval';

  constructor(public no: string) {}
}

/**
 * Cancel purchase order Approval Request, this method will dynamically update the cache purchase order list and selected purchase order.
 *
 * @param no Purchase Order
 */
export class CancelPurchaseOrder {
  static readonly type = '[PO] Cancel Request';

  constructor(public no: string) {}
}

/**
 * Delete purchase order, this method will dynamically update the cache purchase order list and set selected purchase order to null.
 *
 * @param no Purchase Order
 */
export class DeletePurchaseOrder {
  static readonly type = '[PO] Delete';

  constructor(public no: string) {}
}

/**
 * Pick up purchase order, this method will dynamically update the cache purchase order list.
 *
 * @param no Purchase Order
 */
export class PickUpPurchaseOrder {
  static readonly type = '[PO] Pick Up';

  constructor(public no: string) {}
}

/**
 * Set purchase order filter type
 */
export class SetPurchaseOrderFilterType {
  static readonly type = '[PO] Set Filter Type';

  constructor(public type: 'released' | 'open' | 'recent' | 'unset') {}
}

/**
 * Set selected purchase order
 *
 * @param payload New Purchase Order full object. Summary is not supported.
 */
export class SetSelectedPurchaseOrder {
  static readonly type = '[PO] Set';

  constructor(public payload: PurchaseOrder | null) {}
}

/**
 * Get the vendors list
 *
 */
export class GetVendors {
  static readonly type = '[PO] Get Vendors';

  constructor(public bustCache?: boolean) {}
}
