import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { deepClone } from 'fast-json-patch/module/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddNotification } from '@stores-actions/notification.action';
import { UpdateLocalPurchaseOrderLinesFromDocumentCreation } from '@stores-actions/purchase-order-line.action';
import {
  AddPurchaseOrder,
  ApprovePurchaseOrder,
  CancelPurchaseOrder,
  DeletePurchaseOrder,
  GetPurchaseOrder,
  GetPurchaseOrders,
  GetVendors,
  PickUpPurchaseOrder,
  SetPurchaseOrderFilterType,
  SetSelectedPurchaseOrder,
  UpdatePurchaseOrder,
} from '@stores-actions/purchase-order.action';
import { PurchaseOrderStoreService } from '@stores-services/purchase-order-store.service';

import { PurchaseOrder, Vendor } from '@tag/graphql';
import { PurchaseOrderAndLinesReturn, PurchaseOrderPost } from '@api/types';
import { Apollo } from 'apollo-angular';

export class PurchaseOrderStateModel {
  purchaseOrders: PurchaseOrder[] = [];
  vendors: Vendor[] = [];
  filterType: 'released' | 'open' | 'recent' | 'unset' = 'unset';
  selectedPurchaseOrder!: PurchaseOrder | null;
  initialized = false;
}

/**
 * Purchase Order metadata and action mappings.
 *
 * @todo Add the missing endpoints from API (add, update, delete) & Update transloco messages.
 */
@State<PurchaseOrderStateModel>({
  name: 'po',
  defaults: {
    purchaseOrders: [],
    vendors: [],
    filterType: 'unset',
    selectedPurchaseOrder: null,
    initialized: false,
  },
})
@Injectable()
export class PurchaseOrderState {
  constructor(
    private poStoreService: PurchaseOrderStoreService,
    private store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo
  ) {}

  static getPurchaseOrdersFromStatus(status: 'open' | 'released'): any {
    return createSelector(
      [PurchaseOrderState],
      (state: PurchaseOrderStateModel) =>
        state.purchaseOrders.filter((x) => x.status?.toLowerCase() === status)
    );
  }

  @Selector()
  static getPurchaseOrders(state: PurchaseOrderStateModel): PurchaseOrder[] {
    return state.purchaseOrders;
  }

  @Selector()
  static getRecentPurchaseOrderVendors(
    state: PurchaseOrderStateModel
  ): PurchaseOrder[] {
    const currentDate = new Date();
    const referenceDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 14
    );
    return state.purchaseOrders.filter(
      (x) => new Date(x.orderDate ?? '').getTime() >= referenceDate.getTime()
    );
  }

  @Selector()
  static getFilteredPurchaseOrder(
    state: PurchaseOrderStateModel
  ): PurchaseOrder[] {
    switch (state.filterType) {
      case 'open':
        return state.purchaseOrders.filter(
          (x) => x.status?.toLowerCase() === 'open'
        );

      case 'released':
        return state.purchaseOrders.filter(
          (x) => x.status?.toLowerCase() === 'released'
        );

      case 'recent':
        return PurchaseOrderState.getRecentPurchaseOrderVendors(state);

      default:
        return state.purchaseOrders;
    }
  }

  @Selector()
  static getSelectedPurchaseOrder(
    state: PurchaseOrderStateModel
  ): PurchaseOrder | null {
    return state.selectedPurchaseOrder;
  }

  @Selector()
  static getFilterType(state: PurchaseOrderStateModel): string {
    return state.filterType;
  }

  @Selector()
  static getInitStatus(state: PurchaseOrderStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  static getVendors(state: PurchaseOrderStateModel): Vendor[] {
    return state.vendors;
  }

  static getVendorName(
    no: string | undefined
  ): (state: PurchaseOrderStateModel) => string {
    return createSelector(
      [PurchaseOrderState],
      (state: PurchaseOrderStateModel) => {
        if (!no) return '';
        return state.vendors.filter((v) => v.no === no)[0].name ?? no;
      }
    );
  }

  @Action(GetPurchaseOrders, { cancelUncompleted: true })
  getPurchaseOrders(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { filter, bustCache }: GetPurchaseOrders
  ): Observable<PurchaseOrder[]> {
    let state = getState();
    if (state.initialized && !bustCache) return of(state.purchaseOrders);
    return this.poStoreService.fetchPurchaseOrders(filter).pipe(
      tap((result: PurchaseOrder[]) => {
        state = getState();
        setState({
          ...state,
          purchaseOrders: result,
          initialized: true,
        });
      })
    );
  }

  @Action(AddPurchaseOrder)
  addPurchaseOrder(
    { getState, patchState }: StateContext<PurchaseOrderStateModel>,
    { payload }: AddPurchaseOrder
  ): Observable<PurchaseOrderAndLinesReturn> {
    return this.poStoreService.addPurchaseOrder(payload).pipe(
      tap((result: PurchaseOrderAndLinesReturn & { no: string }) => {
        const state = getState();
        result.no = result.purchaseOrderNo;
        const po = result as any;
        patchState({
          purchaseOrders: [...state.purchaseOrders, po],
          selectedPurchaseOrder: po,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderWasCreatedSuccessfullyKeyParam',
          {
            id: po?.no,
          }
        );
        const title = this.translocoService.translate('createKey');

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrders',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderLines',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderComments',
        });

        this.store.dispatch(new AddNotification(message, 'success', title));
      })
    );
  }

  @Action(UpdatePurchaseOrder)
  updatePurchaseOrder(
    { getState, setState, dispatch }: StateContext<PurchaseOrderStateModel>,
    { payload, selectedItem }: UpdatePurchaseOrder
  ): Observable<PurchaseOrderAndLinesReturn> {
    return this.poStoreService.updatePurchaseOrder(payload).pipe(
      tap((result: PurchaseOrderAndLinesReturn & { no: string }) => {
        result.no = result.purchaseOrderNo;
        const state = getState();
        const poList = [...state.purchaseOrders];
        const poIndex = poList.findIndex(
          (item) => item.no === payload.purchase_Order_No
        );
        poList[poIndex] = result as any;
        setState({
          ...state,
          selectedPurchaseOrder:
            selectedItem !== undefined ? selectedItem : (result as any),
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderWasUpdatedSuccessfullyKeyParam',
          {
            id: payload.purchase_Order_No,
          }
        );
        const title = this.translocoService.translate('updatedKey');

        if (result.purchaseOrderLines && result.purchaseOrderLines.length > 0)
          dispatch(
            new UpdateLocalPurchaseOrderLinesFromDocumentCreation(
              result.purchaseOrderLines as any
            )
          );
        dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrders',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderLines',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderComments',
        });
      })
    );
  }

  @Action(ApprovePurchaseOrder)
  approvePurchaseOrder(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { no }: ApprovePurchaseOrder
  ): Observable<any> {
    return this.poStoreService.approvePurchaseOrder(no).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.purchaseOrders.filter(
          (item) => item.no !== no
        );
        setState({
          ...state,
          purchaseOrders: filteredArray,
          selectedPurchaseOrder: null,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderApprovalWasSentSuccessfullyKeyParam',
          {
            id: no,
          }
        );
        const title = this.translocoService.translate('sendApprovalRequestKey');

        this.store.dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrders',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderLines',
        });
      })
    );
  }

  @Action(CancelPurchaseOrder)
  cancelPurchaseOrder(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { no }: CancelPurchaseOrder
  ): Observable<any> {
    return this.poStoreService.cancelPurchaseOrder(no).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.purchaseOrders.filter(
          (item) => item.no !== no
        );
        setState({
          ...state,
          purchaseOrders: filteredArray,
          selectedPurchaseOrder: null,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderApprovalWasCancelledSuccessfullyKeyParam',
          {
            id: no,
          }
        );
        const title = this.translocoService.translate(
          'cancelApprovalRequestKey'
        );

        this.store.dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrders',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderLines',
        });
      })
    );
  }

  @Action(GetPurchaseOrder)
  getPurchaseOrder(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { no }: GetPurchaseOrder
  ): Observable<PurchaseOrder[]> {
    return this.poStoreService.fetchPurchaseOrder(no).pipe(
      tap((result: PurchaseOrder[]) => {
        if (result.length === 0) return;

        const state = getState();
        const poList = [...state.purchaseOrders];
        const po = result[0] || result;
        const poIndex = poList.findIndex((item) => item.no === po.no);
        if (~poIndex) poList[poIndex] = po;
        else poList.push(po);
        setState({
          ...state,
          purchaseOrders: poList,
          selectedPurchaseOrder: po,
        });
      })
    );
  }

  @Action(DeletePurchaseOrder)
  deletePurchaseOrder(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { no }: DeletePurchaseOrder
  ): Observable<PurchaseOrder> {
    return this.poStoreService.deletePurchaseOrder(no).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.purchaseOrders.filter(
          (item) => item.no !== no
        );
        setState({
          ...state,
          purchaseOrders: filteredArray,
          selectedPurchaseOrder: null,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderWasDeletedSuccessfullyKeyParam',
          {
            id: no,
          }
        );
        const title = this.translocoService.translate('deletedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrders',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderLines',
        });
      })
    );
  }

  @Action(PickUpPurchaseOrder)
  pickUpPurchaseOrder(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { no }: PickUpPurchaseOrder
  ): Observable<string> {
    return this.poStoreService.pickUpPurchaseOrder(no).pipe(
      tap((result) => {
        const state = getState();
        const poList: PurchaseOrder[] = deepClone(state.purchaseOrders);
        const poIndex = poList.findIndex((item) => item.no === no);
        poList[poIndex].status = 'Released';
        setState({
          ...state,
          purchaseOrders: poList,
          selectedPurchaseOrder: poList[poIndex],
        });
        this.store.dispatch(
          new AddNotification(result, 'success', 'Purchase Order')
        );

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrders',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderLines',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'purchaseOrderComments',
        });
      })
    );
  }

  @Action(SetSelectedPurchaseOrder, { cancelUncompleted: true })
  setSelectedPurchaseOrder(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { payload }: SetSelectedPurchaseOrder
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedPurchaseOrder: payload,
    });
  }

  @Action(SetPurchaseOrderFilterType)
  setPurchaseOrderFilterType(
    { getState, setState }: StateContext<PurchaseOrderStateModel>,
    { type }: SetPurchaseOrderFilterType
  ): void {
    const state = getState();
    setState({
      ...state,
      filterType: type,
    });
  }

  @Action(GetVendors, { cancelUncompleted: true })
  getVendors(
    { patchState }: StateContext<PurchaseOrderStateModel>,
    { bustCache }: GetVendors
  ): Observable<Vendor[]> {
    return this.poStoreService.fetchVendors().pipe(
      tap((result: Vendor[]) =>
        patchState({
          vendors: result,
        })
      )
    );
  }
}
